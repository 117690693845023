import {
  createContext,
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
  startTransition,
} from 'react';
import Cookie from 'js-cookie';
export const COOKIE_HIDE_INFORMATION_STRIPE = 'HideInformationStripe';

type GeneralContextType = {
  internalLinks: InternalLinks;
  modalDefinitions: ModalsV2PerLanguage;
  isInformationStripeVisible: boolean;
  handleHideInformationStripe: () => void;
};

const defaultContextValue: GeneralContextType = {
  internalLinks: {},
  modalDefinitions: {},
  isInformationStripeVisible: true,
  handleHideInformationStripe: () => null,
};

export const GeneralContext = createContext<GeneralContextType>(defaultContextValue);

interface Props {
  children: ReactNode;
  internalLinks?: InternalLinks;
  modalDefinitions?: ModalsV2PerLanguage;
}

export const GeneralContextProvider = ({
  children,
  internalLinks = {},
  modalDefinitions = {},
}: Props) => {
  const [isInformationStripeVisible, setIsInformationStripeVisible] = useState(true);

  useEffect(() => {
    if (Cookie.get(COOKIE_HIDE_INFORMATION_STRIPE) === 'true') {
      startTransition(() => {
        setIsInformationStripeVisible(false);
      });
    }
  }, []);

  const handleHideInformationStripe = useCallback(() => {
    Cookie.set(COOKIE_HIDE_INFORMATION_STRIPE, 'true');
    setIsInformationStripeVisible(false);
  }, [setIsInformationStripeVisible]);

  const value = useMemo(
    () => ({
      internalLinks,
      isInformationStripeVisible,
      modalDefinitions,
      handleHideInformationStripe,
    }),
    [internalLinks, isInformationStripeVisible, modalDefinitions, handleHideInformationStripe]
  );

  return <GeneralContext.Provider value={value}>{children}</GeneralContext.Provider>;
};

export default GeneralContext;
